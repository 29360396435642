import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import Skeleton from "react-loading-skeleton";
import { BsThreeDots } from "@react-icons/all-files/bs/BsThreeDots";
import { toast } from "react-toastify";
import Link from "next/link";
import isMobile from "is-mobile";
import { useTranslation } from "../context/TranslationContext";
const ListVilla = (props) => {
  const { all, showAll } = useTranslation();
  const isPhone = isMobile();
  const [data, setData] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [error, setError] = useState();
  let DesktopView = false;
  let MobileView = false;
  if (isPhone) {
    MobileView = dynamic(() => import("./ListVilla/MobileView"));
  } else {
    DesktopView = dynamic(() => import("./ListVilla/DesktopView"));
  }

  useEffect(() => {
    props.fetch
      .then((response) => {
        if (response.data.error) {
          setError(response.data.error);
          setData(false);
        } else {
          setData(response.data);
          setError(false);
        }
        setisLoading(false);
      })
      .catch((error) => {
        toast.error(error.message);
        setError(error.message);
      });
  }, []);

  if (error) {
    return false;
  }

  return (
    <div
      className={
        (props.className ? props.className : "odd:bg-brand-gray-7 bg-white") +
        " py-4 lg:pt-16 lg:pb-28 overflow-hidden ListVilla"
      }
    >
      <div className="container">
        <div className="flex flex-col gap-y-6 lg:gap-y-16">
          <div className="flex items-center">
            {props.IconUrl && (
              <div className="mr-2 lg:hidden">{props.IconUrl}</div>
            )}

            <div className="flex flex-col">
              <span className="font-openSans text-xl lg:text-3xl text-brand-black lg:text-orange">
                {props.baslik}
              </span>
              <span className="text-sm text-brand-gray mt-1">
                {props.description}
              </span>
            </div>
            {props.showall && (
              <Link href={props.showall}>
                <a className="text-brand-black font-bold text-13 lg:hidden ml-auto">
                  {all}{" "}
                  <BsThreeDots
                    size="36"
                    className="inline-block text-brand-blue"
                  ></BsThreeDots>
                </a>
              </Link>
            )}
            {props.showall && (
              <Link href={props.showall}>
                <a
                  href={props.showall}
                  className="bg-primary font-ma font-bold !leading-[55px] text-sm text-white px-9 ml-auto hidden lg:block"
                >
                  {showAll}
                </a>
              </Link>
            )}
          </div>
          {error ? (
            <div>aa</div>
          ) : !data ? (
            <div className="flex gap-x-4">
              <div className="w-1/3">
                <Skeleton height={457} />
              </div>
              <div className="w-1/3">
                <Skeleton height={457} />
              </div>
              <div className="w-1/3">
                <Skeleton height={457} />
              </div>
            </div>
          ) : (
            <>
              {DesktopView ? <DesktopView data={data} /> : null}
              {MobileView ? <MobileView data={data} /> : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListVilla;
